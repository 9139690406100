import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from "../images/logo.svg"

const Header = ({ data, siteTitle }) => (
  <header>
    <div className="container">
      <div className="row py-4 py-md-5">
        <div className="col col-6">
          <Link to="/">
            <img src={logo} className="img img-fluid logo" alt={siteTitle} />
          </Link>
        </div>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
